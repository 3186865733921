import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function Security({
  children
}) {
  return <LegalDoc title="Security" updated="9 Dec 2019">{children}</LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Security is essential to everything we do. We’ve built advanced security into our products from the ground up to make them secure by design. Below, we outline how we protect customers’ data.  If you have specific questions or concerns, contact us at `}<a parentName="p" {...{
        "href": "mailto:security@fidelapi.com"
      }}>{`security@fidelapi.com`}</a>{`.`}</p>
    <h2>{`Accessing customer data`}</h2>
    <h3>{`Access controls`}</h3>
    <p>{`Role-based access controls are enforced at each layer of infrastructure. Multi-factor authentication is required for access to Fidel infrastructure. All application and user access logs are stored centrally and monitored.`}</p>
    <h3>{`Traffic controls`}</h3>
    <p>{`Fidel API only allows client requests using strong TLS protocols and ciphers. Communication between Fidel infrastructure and financial institutions is transmitted over encrypted tunnels. All client communication with Fidel API requires API key authentication and utilizes cryptographically hashed headers and timestamps to verify authenticity.`}</p>
    <h2>{`Protecting customer data`}</h2>
    <p>{`We can’t access your details, and neither can anyone else. As soon as a customer links their card through a secure SDK iFrame and TLS channel, Fidel encrypts their details with bank-level security encryption. That encryption — or tokenization — replaces the customers’ details with a token ID. so Visa, Mastercard or Amex can let us know when a consenting customer has made a transaction.`}</p>
    <p>{`Fidel is PCI Level 1 compliant, which means we have been approved by an independent Qualified Security Assessor (QSA) to safely and securely handle cardholder data during credit card transactions. The Payment Card Industry Data Security Standard (PCI DSS - 2006) is an industry-wide standard created by the five largest card networks ensure that card payment processors safely and securely accept, store, process, and transmit cardholder data.
All personal information we collect is stored on secure servers. We ensure that the personal information we hold is protected from misuse, interference, loss, unauthorised access, modification or disclosure through various methods including access limitation, and industry-standard Secure Socket Layer (SSL) encryption technology. Security safeguards include data encryption, firewalls, and physical access controls to buildings. `}</p>
    <h2>{`Routine tests`}</h2>
    <p>{`Fidel runs routine (nightly) tests and regularly undergoes external network penetration tests, and third-party code reviews. We publish all issues on our `}<a parentName="p" {...{
        "href": "https://status.fidel.uk"
      }}>{`Status page`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      